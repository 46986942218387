import { ref, Ref, computed } from 'vue'
import axios from 'axios'

const url = (id: number): string => `${ route('comparison') }/${ id }`

export const comparisonIds: Ref<number[]> = ref([])

export const comparisonCount = computed(() => comparisonIds.value.length)

export const addComparison = async (listingId: number): Promise<void> => {
    const { data } = await axios.post(url(listingId))

    if(Array.isArray(data.items)) {
        setComparisons(data.items)
    } else {
        setComparisons(Object.values(data.items))
    }
}

export const removeComparison = async (listingId: number): Promise<void> => {
    const { data } = await axios.delete(url(listingId))

    if(Array.isArray(data.items)) {
        setComparisons(data.items)
    } else {
        setComparisons(Object.values(data.items))
    }
}

export const setComparisons = (items: number[]): void => {
    comparisonIds.value = items
}
